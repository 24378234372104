const allRoutes = {
    'index': {
        label: 'Accueil',
        pathname: '/',
        parent: false,
    },
    'index.reset': {
        label: 'Mot de passe',
        pathname: '/login/recovery',
        parent: false,
    },
    'index.mentions': {
        label: 'Mentions',
        pathname: '/public/mentions',
        parent: false,
    },
    'public.redirectingway': {
        label: 'Redirection',
        pathname: '/chv',
        parent: false,
    },
    'public.redirectingway.ijb': {
        label: 'Redirection',
        pathname: '/ijb',
        parent: false,
    },
    'private.mentions': {
        label: 'Mentions',
        pathname: '/mentions',
        parent: false,
    },
    'private.help': {
        label: 'Aide',
        pathname: '/help',
        parent: false,
    },
    'private.consent': {
        label: 'Consentement',
        pathname: '/consent',
        parent: false,
    },
    'private.patient.edit': {
        label: 'Admin',
        pathname: '/patient/edit/:id',
        parent: false,
    },
    'private.patient.add': {
        label: 'Admin',
        pathname: '/patient/add',
        parent: false,
    },
    'private.patients.listing': {
        label: 'Admin',
        pathname: '/patients/listing',
        parent: false,
    },
    'private.alerts.listing': {
        label: 'Admin',
        pathname: '/alerts/listing',
        parent: false,
    },
    'private.alertsic.listing': {
        label: 'Admin',
        pathname: '/alertsic/listing',
        parent: false,
    },
    'private.alertscurve.listing': {
        label: 'Admin',
        pathname: '/alertscurve/listing',
        parent: false,
    },
    'private.alertsrds.listing': {
        label: 'Admin',
        pathname: '/alertsrds/listing',
        parent: false,
    },
    'private.account': {
        label: 'Admin',
        pathname: '/account/edit',
        parent: false,
    },

    // 'private.programs.edit': {
    //     label: 'programs',
    //     pathname: '/content/programs/:id',
    //     component: Programs,
    //     parent: false,
    // },
}

export { allRoutes }
